import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import ruTranslation from './locales/ru/translation.json';
import tgTranslation from './locales/tg/translation.json';
import kkTranslation from './locales/kk/translation.json';

const resources = {
  en: { translation: enTranslation },
  ru: { translation: ruTranslation },
  tg: { translation: tgTranslation },
  kk: { translation: kkTranslation },
};

const savedLanguage = localStorage.getItem('i18nextLng') || 'ru';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('i18nextLng', lng);
});

export default i18n;
